<template>
  <div id="changePasswordModal" class="app-modal modal fade" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered overflow-hidden">
      <div class="modal-content  position-relative">
        <div class="app-modal-body widget-tracker widget-tracker-active">
          <div class="app-modal-header border-0">
            <p class="modal-title h2 text-main fw-bold">Şifrənin dəyişdirilməsi</p>
            <p class="modal-subtitle mt-0">
              Əvvəlcə köhnə şifrəni daha sonra yeni şifrəni
              daxil edərək şifrənizi yeniliyə bilərsiz.
            </p>
            <button type="button" class="app-modal-close" data-bs-dismiss="modal"></button>
          </div>

          <form @submit.prevent="submitHandler">
            <div class="form-group mt-3 position-relative" :class="{ error: v$.form.oldPassword.$errors.length }">
              <password-input title="Köhnə şifrə" v-model:inputData="form.oldPassword" />
            </div>
            <div class="form-group mt-3 position-relative" :class="{ error: v$.form.newPassword.$errors.length }">
              <password-input title="Yeni şifrə" v-model:inputData="form.newPassword" />
            </div>
            <div class="form-group mt-3 position-relative" :class="{ error: v$.form.confirmNewPassword.$errors.length }">
              <password-input title="Yeni şifrəni təkrarlayın" v-model:inputData="form.confirmNewPassword" />
            </div>

            <p class="otp-error text-right mt-3 ms-1">{{ errorMessage }}</p>

            <button type="submit" class="btn-main btn-default btn-hover cursor-pointer mt-3 d-inline-block w-100"
              :disabled="isLoading">
              <span class="spinner-border spinner-border-sm" v-if="isLoading" />
              Təsdiq edin
            </button>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import api from "@/api/account.api";

const initalValue = {
  oldPassword: null,
  newPassword: null,
  confirmNewPassword: null,
};

const validations = {
  oldPassword: { required },
  newPassword: { required },
  confirmNewPassword: { required },
};

export default {
  data: () => ({
    isLoading: false,
    modal: null,
    form: { ...initalValue },
    errorMessage:null
  }),
  validations: () => ({
    form: { ...validations },
  }),
  setup: () => ({ v$: useVuelidate() }),
  mounted() {
    this.modal = new Modal(document.getElementById("changePasswordModal"));
  },
  methods: {
    clear() {
      this.isLoading = false;
      this.form = { ...initalValue };
    },

    show() {
      this.clear();

      this.modal.show();
    },

    async submitHandler() {
      const result = await this.v$.$validate();
      if (result) {
        if(this.form.newPassword==this.form.confirmNewPassword){
          this.isLoading = true;
          api
          .changePassword(this.form)
          .then(() => {
            this.modal.hide();
          })
          .catch((error) => this.emitter.emit("error", error))
          .finally(() => (this.isLoading = false));
        }
        else{
          this.errorMessage="Daxil edilən şifrələr uyğun deyil."
        }
      }
    },
  },
};
</script>