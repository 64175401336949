<template>
  <div class="personal-cabinet">
    <p class="mt-2 mb-3">
      <span class="h5 personal-cabinet-head">
        Şəxsi məlumatlar
        <span class="spinner-border spinner-border-sm" v-if="isLoading" />
      </span>
    </p>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <p class="personal-cabinet-label mb-2">Ad və Soyad</p>
        <p class="personal-cabinet-item">
          <span >
            {{ user?.fullName }}
          </span>
        </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <p class="personal-cabinet-label mb-2">Mobil</p>
        <p class="personal-cabinet-item">
          <span >
            {{ user?.phoneNumber }}
          </span>
        </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <p class="personal-cabinet-label mb-2">Email</p>
        <p class="personal-cabinet-item">
          <span v-if="user?.email">
            {{ user?.email }}
          </span>
          <span>
            Email yoxdur
          </span>
        </p>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <p class="personal-cabinet-label mb-2">Şifrə</p>
        <p class="personal-cabinet-item last d-flex justify-content-between">
          <span >******************</span>
          <img  @click.prevent="changePassword" src="@/assets/images/edit-password.svg" class="cursor-pointer"  />
        </p>
      </div>
    </div>
  </div>

  <change-password-modal ref="changePasswordModal" />
</template>

<script>
import ChangePasswordModal from "./ChangePasswordModal.vue";

import api from "@/api/profile.api";

export default {
  components: { ChangePasswordModal },
  data: () => ({
    isLoading: false,
    user: null,
  }),
  methods: {
    load() {
      this.isLoading = true;

      api
        .getUser()
        .then((data) => (this.user = data))
        .catch((error) => this.emitter.emit("error", error))
        .finally(() => (this.isLoading = false));
    },

    changePassword() {
      this.$refs["changePasswordModal"].show();
    },
  },
  created() {
    this.load();
  },
};
</script>