import httpClient from "./httpClient/api";

const login = (username, password) => httpClient.post("/account", { username, password });

const changePassword = (data) => httpClient.post("/account/password/change", data);

const otpGenerate = (phoneNumber) => httpClient.post("/otp/generate", {phoneNumber});

const otpVerify = (otpId,phoneNumber,otpCode) => httpClient.post("/otp/verify", {otpId,phoneNumber,otpCode});

const resetPassword = (phoneNumber,newPassword) => httpClient.post("/account/password/reset", {phoneNumber,newPassword});

export default {
  login,
  changePassword,
  otpGenerate,
  otpVerify,
  resetPassword
}